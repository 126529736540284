// 错误
.errorTitle {
  font-size: 14px;
  font-family: 'MicrosoftYaHei';
  color: #333333;
}

.errorDesc {
  font-size: 12px;
  font-family: 'MicrosoftYaHei';
  color: #C1C2C5;
}

