
.descriptionsRow {
  margin-bottom: 20px;
  border-bottom: 1px solid #F9F9F9;

  :global {
    .ant-form-item {
      margin-bottom: 0px;
    }

    .ant-form-item-label {
      line-height: normal;
    }
  }
}

.content {
  font-size: 14px;
  color: #C1C2C5;
  font-weight: 400;
}

.thumbWrap {
  display: block;
  margin: 12px 8px 8px 0px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 104px;
  height: 104px;
  overflow: hidden;

  .thumbInfo {
    position: relative;
    height: 100%;
    overflow: hidden;
    transition: background-color 0.3s;

    img {
      position: static;
      width: 100%;
      height: 100%;
    }
  }
}