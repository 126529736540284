.settingForms {
  width: 420px;

  :global {
    .ant-form-item-label {
      line-height: normal;
    }
  }
}

.flexbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexContent {
  font-size: 14px;
  color: #333333;
}

.mobile {
  margin-left: 10px;
  font-weight: bold;
}
