.preview {
  position: relative;
}

.none {
  display: none;
}

.previewZoom {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  width: 300px;
  height: 300px;
  align-items: center;
  justify-content: center;
  border: 1px solid #E8E8E8;
}

.previewPic {
  display: block;
  margin: 0px auto;
  max-width: 100%;
  max-height: 100%;
  border: none;
}

.magnifier {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 150px;
  height: 150px;
  border: 1px solid #CCCCCC;
  background-color: rgba(243, 210, 76, 0.5);
  cursor: move;
}

.zoomBox {
  position: absolute;
  top: 0px;
  left: 300px;
  width: 420px;
  height: 420px;
  border: 1px solid #E8E8E8;
  overflow: hidden;
  z-index: 20;
  background-color: rgb(255, 255, 255);
}

.zoompic {
  display: block;
  width: 800px;
}

.swiperLand {
  position: relative;

  .swiperPrev {
    display: block;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 20px;
    line-height: 60px;
    color: #0169C6;
  }

  .swiperNext {
    display: block;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 20px;
    line-height: 60px;
    color: #0169C6;
  }
}

.galleryThumbs {
  width: 260px;
  overflow: hidden;
}

.picSlide {
  width: 60px;
  height: 60px;

  .imgBox {
    display: flex;
    align-items: center;
    width: 60px;
    height: 60px;
    border: 2px solid #ffffff;
    overflow: hidden;

    &.cur {
      border: 2px solid #F5222D;
    }

    .slideImg {
      display: block;
      width: 100%;
    }
  }
}

.swiperPrev {
  width: 20px;
  left: 0px;
}

.swiperNext {
  width: 20px;
  right: 0px;
}