.globalHeader {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0px 36px;
  height: 100%;
}

.headerLogo {
  padding-left: 34px;
  font-size: 24px;
  color: #FFFFFF;
}

.logoPic {
  margin-right: 10px;
  margin-top: -5px;
}

.headerContent {
  flex: 1 1 0%;
  display: flex;
  align-items: center;
  height: 100%;
}

.breadcrumbBox {
  margin-left: 124px;
}

.headerSpace {
  margin-left: 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.spaceItem {
  padding: 0px 8px;
  color: #FFFFFF;

  &:hover {
    color: #FFFFFF;
  }
}