.topHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
}

.topMenus {
  display: flex;
}

.menuItem {
  width: 320px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  font-size: 24px;
  color: #555555;
  cursor: pointer;

  &.active {
    background-image: url('~@/assets/images/tabimages/tabs_active_2.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #FFFFFF;
  }
}
