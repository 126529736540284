.w1200 {
  margin: 0px auto;
  padding: 20px;
  width: 1200px;
  background-color: #ffffff;
}

.hiddlen {
  visibility: hidden;
  color: transparent;
}

.floor {
  margin-bottom: 15px;
}

.productIntro {
  display: flex;
}

.previewWrap {
  width: 240px;

  .previewZoom {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 240px;
    height: 240px;
    overflow: hidden;
    border: 1px solid #e8e8e8;

    .previewPic {
      display: block;
      max-width: 100%;
      max-height: 100%;
      margin: 0px auto;
      border: none;
    }
  }

  .galleryThumbs {
    position: relative;

    .specnextward {
      position: absolute;
      width: 22px;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
      text-align: center;
      color: rgba(0, 0, 0, 0.2);
      z-index: 9;
    }

    .specprevward {
      position: absolute;
      width: 22px;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
      text-align: center;
      color: rgba(0, 0, 0, 0.2);
      z-index: 9;
    }
  }

  .specWrap {
    margin: 0px auto;
    width: 192px;
  }

  .swiperPrev {
    width: 22px;
    left: 0px;
    opacity: 0;
  }

  .swiperNext {
    width: 22px;
    right: 0px;
    opacity: 0;
  }

  .spec {
    display: flex;
    align-items: center;
    width: 60px;
    height: 60px;
    overflow: hidden;

    &.cur {
      border: 1px solid #324972;
    }
  }

  .slideImg {
    display: block;
    width: 60px;
    cursor: pointer;
  }
}

.infoZoom {
  flex: 1;
  padding-top: 15px;
  padding-right: 20px;
  margin-left: 30px;

  .name {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }

  .price {
    padding: 10px 0px;
    font-size: 22px;
    color: #F5222D;
  }
}

.chooseWrap {

  .chooseItem {
    margin-bottom: 5px;
    font-size: 14px;
    color: #333333;
    line-height: normal;

    &:last-child {
      margin-bottom: 0px;
    }

    .chooseLabel {
      position: relative;
      float: left;
      width: 84px;

      &:after {
        position: relative;
        content: ':';
        top: 0px;
        margin: 0px 0px 0px 2px;
      }
    }

    .chooseValue {
      margin-left: 84px;
    }
  }
}

.attrIntro {
  border: 1px solid #e8e8e8;

  .introHeader {
    padding: 8px 16px;
    font-size: 14px;
    color: #333333;
    border-bottom: 1px solid #e8e8e8;
  }

  .attrItem {
    padding: 8px 16px;

    .attrLabel {
      margin-bottom: 4px;
      font-size: 12px;
      color: #666666;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .attrValue {
      font-size: 14px;
      color: #333333;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.tabBar {
  position: relative;
  border-bottom: 1px solid #e8e8e8;
  outline: none;

  .tabItem {
    position: relative;
    display: inline-block;
    height: 100%;
    margin: 0 32px 0 0;
    padding: 4px 16px;
    font-size: 14px;
    color: #333333;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &.tabActive {
      color: #F5222D;
      font-weight: 500;
    }
  }
}

.popoverContent {
  position: relative;
  color: #333333;

  .popoverClose {
    font-size: 14px;
    color: #F5222D;
  }
}

.detailWrap {
  width: 1200px;
  margin: 0px auto;
}

.picList {
  .picItem {
    margin-bottom: 12px;
    text-align: center;

    &:last-child {
      margin-bottom: 0px;
    }

    .detailimg {
      width: 100%;
    }
  }
}

.echartCol {
  border: 1px solid #e8e8e8;

  .colTitle {
    padding: 10px 15px;
    border-bottom: 1px solid #e8e8e8;
  }
}

.branchsty {
  padding: 1px 2px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: 0;
  border: 1px solid #155BD4;
  border-radius: 2px;
  font-size: 12px;
  color: #155BD4;
  word-break: break-all;
  line-height: normal;
}

.futuresTag {
  border-color: #F5222D;
  color: #F5222D;
}

.productprice {
  margin-top: 40px;
  width: 680px;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  margin-bottom: 30px;

  .Pul {
    display: flex;
    align-items: center;
    margin: 10px;

  }

  .Pprice::before {
    content: '\00A5';
    margin-right: 1px;
    margin-left: 20px;
  }
}

.lastli {
  flex: 1;
  text-align: right;
}

.PInfo {
  width: 200px;
  margin-left: 40px;
}

.GoodsInber {
  width: 100px;
  flex: 1;
  display: flex;
  justify-content: space-between;

}

.Pnum {
  margin: 0 auto;
  color: #FF7900;
  font-size: 12px;
  font-family: MicrosoftYaHei;
}