.addLand {
  margin-bottom: 16px;
}

.list {
  padding: 0px;
}

.listCard {
  padding: 8px 16px;
  margin-bottom: 16px;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  list-style: none;

  &.background {
    background-color: rgba(21, 91, 212, 0.06);
    border: none;
  }
}

.extraLand {
  text-align: right;
}

.defaultTag {
  padding: 2px 6px;
  margin: 0 0 0 12px;
  font-size: 12px;
  background-color: #269c42;
  color: #ffffff;
  font-weight: 400;
}
