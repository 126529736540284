.statisticbox {
  display: flex;
  padding: 50px 35px;
  justify-content: space-between;
  border: 1px solid #D5D9DB;
}

.statisticContent {
  flex: 1;
  display: flex;
}

.picBox {
  margin-right: 24px;
  width: 50px;
  height: 50px;
  background-image: url('~@/assets/images/accountM.png');
  background-repeat: no-repeat;
}

.listItem {
  display: flex;
  align-items: center;
  padding: 20px 34px 20px 10px;
  margin-bottom: 20px;
  background: #FFE8E8;
  border-radius: 4px;
}

.listItemMeta {
  flex: 1;
  align-items: flex-start;
}

.metaTitle {
  margin-bottom: 4px;
  font-size: 14px;
  color: #000000;
}