body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F3F5F9;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
  margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
  list-style: none;
}

.ant-layout {
  background-color: #F3F5F9;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr >td {
  padding: 8px 10px;
}

.clearfix {
  zoom: 1;
}

.clearfix::after {
  content: '';
  height: 0;
  line-height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}

.redColor {
  color: #F5222D;
}

.greenColor {
  color: #52C41A;
}

.darkBackground {
  background-color: #D4DCE4;
}

.label {
  position: relative;
  color: rgba(0, 0, 0, 0.85);
}

.label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.required:before {
  display: inline-block;
  margin-right: 4px;
  color: #F5222D;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}