
.topTips {
  height: 90px;
  background: #F3F6FF;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tipsLefe {
    margin-left: 30px;
    font-weight: 400;
    font-size: 16px;
    font-family: Microsoft YaHei;
  }

  .tipRight {
    margin-right: 30px;
    text-align: right;
    font-weight: 400;
    font-size: 16px;
    font-family: Microsoft YaHei;

    div {
      margin: 2px 0px;
    }

    .showMoneny {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 29px;
      color: #FF4B4A;
    }
  }
}

.bankInfo {
  display: flex;
  border: 1px solid #FAFAFA;
  border-radius: 2px;
  height: 370px;

  .bankUs {
    display: flex;
    width: 120px;
    flex-direction: column;
    align-items: center;
    background-color: #FAFAFA;

    div {
      margin: 20px 0px;
    }
  }

  .bankcontent {
    margin-left: 30px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    div {
      margin: 20px 0px;
    }
  }

  .bankFaly {
    display: flex;
    width: 100px;
    flex-direction: column;
    align-items: center;
    border: 1px solid #E8E8E8;

    div {
      margin: 20px 0px;
    }
  }
}

.content {
  display: flex;
}

.Tpis {
  height: 370px;
  border: 1px solid #E8E8E8;
  border-radius: 2px;

  .TipsTitle {
    width: 90%;
    margin: 0 auto;
    margin-top: 30px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 17px;
    color: #333333;
  }

  .tipsInfo {
    width: 90%;
    margin: 0 auto;
    font-size: 12px;

    .tipsContent {
      margin: 20px 0px;
    }
  }
}