.basicLayout {
  background-color: #F3F5F9;
}

.header {
  height: 94px;
  line-height: 94px;
  background-color: transparent;
}

.fixedHeader {
  position: fixed;
  top: 0px;
  right: 0px;
  padding: 0px;
  width: 100%;
  height: 94px;
  line-height: 94px;
  z-index: 100;
  background-image: url('~@/assets/images/fixed_header_background.png');
  background-repeat: no-repeat;
}

.layoutContent {
  margin: 20px 36px 40px 36px;
}

.themeSearch {
  padding: 24px 24px 16px 24px;
  background: linear-gradient(90deg, #6B4FFF 0%, #8C33FD 100%);

  :global {
    .ant-form-item-label > label {
      color: #FFFFFF;
    }

    .ant-btn {
      background-color: transparent;
      border-color: #D5D9DB;
      color: #FFFFFF;
    }

    .ant-btn-primary {
      background-color: #493F91;
      border-color: #493F91;
    }
  }
}