.flexbox {
  display: flex;
}

.matchStatistic {
  margin-bottom: 20px;
}

.matchItem {
  flex: 1;
  width: 513px;
  height: 126px;
  background-repeat: no-repeat;
  background-size: cover;
}

.matchContent {
  display: flex;
  align-items: center;
  height: 126px;
}

.statisticbox {
  width: 130px;
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
}

.value {
  font-size: 24px;
  font-weight: 400;
}

.slider {
  position: relative;
  display: inline-block;
  margin-left: 46px;
  width: 236px;
  vertical-align: middle;
  background-color: #FFFFFF;
  border-radius: 14px;
}

.rail {
  position: relative;
  height: 25px;
  border-radius: 14px 0px 0px 14px;
  transition: all 0.6s cubic-bezier(.08, .82, .17, 1) 0s;
}

.total {
  background-image: url('~@/assets/images/customer_match_total.png');

  .rail {
    border-radius: 14px;
    background-color: #341C6F;
  }
}

.success {
  background-image: url('~@/assets/images/customer_match_success.png');

  .rail {
    background-color: #0E606F;

    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0px;
      content: '';
      display: block;
      width: 3px;
      height: 65px;
      background-color: #FFFFFF;
    }
  }
}

.fail {
  background-image: url('~@/assets/images/customer_match_fail.png');

  .rail {
    background-color: #80490A;

    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0px;
      content: '';
      display: block;
      width: 3px;
      height: 65px;
      background-color: #FFFFFF;
    }
  }
}

.fullRail {
  .rail {
    border-radius: 14px;

    &::after {
      display: none;
    }
  }
}
