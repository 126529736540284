.footerBar {
  position: fixed;
  bottom: 0px;
  right: 0px;
  padding: 8px 36px 8px 48px;
  width: calc(100% - 292px);
  background-color: #FFFFFF;
  z-index: 99;
}

.goodsAccount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
}

.actions {
  padding-left: 15px;
}

.deleteLinkBtn {
  margin-left: 8px;
  color: #333333;

  &:hover, &:focus, &:active {
    color: #333333;
  }
}

.contents {
  display: flex;
  align-items: center;
  height: 46px;
  font-size: 14px;
  color: #333333;
}

.money {
  font-size: 24px;
  color: #F5222D;
  font-weight: 700;
}
