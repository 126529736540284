
$ctimg: './../../assets/images/ct.png';

.main {
  position: relative;
  background-image: url('~@/assets/images/bg_login.png');
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  width: 100%;
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
}

.section {
  position: absolute;
  top: 50%;
  right: 205px;
  transform: translate3d(0, -50%, 0);
  padding-top: 84px;
  padding-bottom: 60px;
  width: 483px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 1, 0.1);
}

.loginHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin: 0px 32px 48px 32px;
}

.vertical {
  margin-left: 40px;
  margin-right: 40px;
  height: 38px;
}

.description {
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: normal;
}

.descriptionEn {
  font-size: 12px;
  color: #FFFFFF;
  line-height: normal;
}

.loginContainer {
  margin: 0px 32px;
}

.formRow {
  background-color: #FFFFFF;

  :global {
    .ant-input {
      border: none;
      border-radius: none;
      border-radius: none;
    }

    .ant-input:focus {
      box-shadow: none;
    }

    .ant-form-explain {
      padding-left: 11px;
      padding-right: 11px;
    }

    .ant-form-item-label {
      line-height: 34px;
    }

    .ant-form-item-required::before {
      visibility: hidden;
    }
  }
}

.rowLabel {
  padding: 11px 11px 0px 11px;
  color: #1A1A1A;
}

.formInput {
  border: none;
  border-radius: none;
}

.btnsWrap {
  margin-top: 100px;
  text-align: center;
}

.btn {
  width: 172px;
  height: 50px;
  border-radius: 4px;
  background-color: #196DFF;
}