.header {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #333333;
  font-weight: 600;
  border-bottom: 1px solid #E8E8E8;
}

.listItem {
  height: 48px;
  line-height: 48px;
  border-bottom: 1px dashed #E8E8E8;
  color: #333333;
}

.itemContent {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 16px;
}

.carouselWrap {
  height: 276px;
  overflow: hidden;

  :global {
    .ant-carousel .slick-list {
      overflow: initial;
    }
  }
}