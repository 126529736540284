.addressRow {
  padding: 8px 15px;
  margin-bottom: 16px;

  border-radius: 2px;
}

.extraRow {
  text-align: right;

  .extraItem {
    margin-right: 15px;

    &:hover {
      color: #F5222D;
    }
  }
}

.defaultLabel {
  margin: 0 0 0 12px;
  font-size: 12px;
  background-color: rgba(38, 156, 66, 1);
  padding: 2px 6px;
  color: rgba(255, 255, 255, 1);
  font-weight: 400
}

.from {
  :global {
    .ant-form-item {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5;
      list-style: none;
      -webkit-font-feature-settings: 'tnum';
      font-feature-settings: 'tnum', "tnum";
      margin-bottom: 2px;
      vertical-align: top;
    }
  }
}

.nocheck {
  background-color: white;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
}

.ischeck {
  background-color: rgba(21, 91, 212, 0.06);
}