
.leftSider {
  padding-top: 94px;
  position: fixed;
  top: 0px;
  left: 36px;
  z-index: 100;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  background-color: transparent;
}

.asideWrap {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  height: 100%;
}

.asideContent {
  flex: 1 1 0%;
  overflow: hidden auto;
  padding-top: 16px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 3px 6px 1px rgba(95, 74, 246, 0.0784);

  :global {
    .ant-menu {
      background-color: transparent;
    }

    .ant-menu-inline {
      border-right: none;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      color: #5F4AF6;
      background-color: rgba(238, 236, 255, 1);
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 100%, 0.2);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: hsla(0, 0%, 100%, .15);
    border-radius: 3px;
  }
}