.tag {
  display: inline-block;
  padding: 1px 6px;
  margin-right: 5px;
  font-size: 12px;
  color: #ffffff;
  border-radius: 0px;
  line-height: normal;

  &.spotTag {
    background-color: #0169C6;
  }

  &.futuresTag {
    background-color: #F5222D;
  }

  &.cloudTag {
    background-color: #FF7744;
  }
}

.searchLand {
  :global {
    .ant-input {
      border-left: 1px solid #F5222D;
      border-right: 1px solid #F5222D;
      border-top: 1px solid #F5222D;
      border-bottom: none;
      border-radius: 0px;
    }

    .ant-input:hover {
      border-color: #F5222D;
    }

    .ant-input:focus {
      border-color: #F5222D;
      box-shadow: none;
    }
  }
}

.resultLand {
  min-height: 220px;
  border: 1px solid #F5222D;
}

.resultRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  text-align: left;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background-color: rgba(51, 51, 51, 0.05);
  }
}

.resultContainer {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #333333;

  .brandTag {
    display: inline-block;
    padding: 1px 2px;
    margin-right: 5px;
    border-radius: 2px;
    border: solid 1px #0169C6;
    font-size: 12px;
    color: #0169C6;
    line-height: normal;
  }
}

.resultBtns {
  flex: 0 0 auto;
  margin-left: 8px;
  font-size: 0;
}