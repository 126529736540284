.steps {
  display: flex;
  padding: 56px 148px;
  width: 100%;
  border: 1px solid #E7EBEF;
  font-size: 0;
}

.stepsItem {
  position: relative;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  vertical-align: top;
  margin-right: 16px;
  white-space: nowrap;

  &:last-child {
    flex: none;
    margin-right: 0px;
  }
}

.itemContainer {
  display: flex;
  outline: none;
}

.itemIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
  font-size: 26px;
  line-height: 36px;
  text-align: center;
  border-radius: 36px;
  background-color: #D5D9DB;
  color: #FFFFFF;
}

.itemTitle {
  position: relative;
  padding-right: 16px;
  color: #D5D9DB;
  font-size: 24px;
  line-height: 36px;
}

.active {
  .itemIcon {
    background-color: #5F4AF6;
  }

  .itemTitle {
    color: #5F4AF6;
  }
}

.itemTail {
  flex: 1;

  .tail:first-child {
    margin-left: 0px;
  }
}

.tailContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
}

.tail {
  display: inline-block;
  vertical-align: top;
  width: 10px;
  height: 10px;
  margin-left: 8px;
  background-color: #D5D9DB;
  border-radius: 5px;
}




