.tabsLand {
  margin-bottom: 16px;
  padding-top: 8px;
  background-color: #FFFFFF;

  :global {
    .ant-tabs-bar {
      margin-bottom: 0px;
    }
  }
}

.formlabelExtra {
  margin-left: 10px;
  font-size: 12px;
  color: #C1C2C5;
}