.addressRow {
  padding: 8px 15px;
  margin-bottom: 16px;

}

.extraRow {
  text-align: right;

  .extraItem {
    margin-right: 15px;

    &:hover {
      color: #F5222D;
    }
  }
}

.defaultLabel {
  font-size: 12px;
  font-weight: 400;
  background-color: rgba(38, 156, 66, 1);
  padding: 2px 6px;
  color: rgba(255, 255, 255, 1);
}

.ischeck {
  background-color: rgba(21, 91, 212, 0.06);
}

.nocheck {
  background-color: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
}